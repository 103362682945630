/**
 * @render react
 * @name BuyBox
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'recompose';
import ReactGA from 'react-ga';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { Icon } from '@autovia-uk/polaris-components/components/atoms/Icon';
import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { Price } from '@autovia-uk/polaris-components/components/atoms/Price';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';

import './_style.scss';

const BuyBox = ({
  context: {
    cssPrefix,
  },
  description,
  extraClassNames,
  extraItem,
  id,
  items,
  label,
  loadStyles,
  partnerLogo,
  title,
  subtitle,
}) => {
  /**
   * @param {string} ctalabel
   * @param {string} ctaGA4Label
   * @param {string} ctaGA4Partner
   */
  const handleLinkClick = ({ ctalabel, ctaGA4Label, ctaGA4Partner }) => {
    ReactGA.event({
      category: 'Buy Box',
      action: 'CTA Link Click',
      label: ctalabel,
    });

    window.dataLayer.push({
      event: 'buybox.button_click',
      'buybox.button': ctaGA4Label,
      'buybox.partner': ctaGA4Partner,
    });
  };

  const itemHtml = (item) => {
    const hasPrice = item.price?.first || item.price?.second || item.price?.savings;
    return (
      <>
        <div
          className={classNames({
            [`${cssPrefix}__buybox-item`]: true,
            '-hasPrice': hasPrice,
            [`${item.extraClassName}`]: true,
          })}
        >
          <div className={`${cssPrefix}__buybox-item__details`}>
            <div className={`${cssPrefix}__buybox-item__title`}>
              <Link
                extraClassNames={{
                  [`${cssPrefix}__buybox-item__title-link`]: true,
                }}
                href={item.ctaUrl}
                rel="sponsored"
                screenReaderText={item.title}
                onClick={() => handleLinkClick({ ctalabel: item.ctaGALabel, ctaGA4Label: item.ctaGA4Label, ctaGA4Partner: item.ctaGA4Partner })}
              >
                {item.title}
              </Link>
            </div>
            <div className={`${cssPrefix}__buybox-item__subtitle`}>
              {hasPrice ? (
                <div>
                  {item.price.first && (
                  <span>
                    From:
                    <Price
                      extraClassNames={{
                        [`${cssPrefix}__buybox-item__price`]: true,
                      }}
                      min={item.price?.first}
                      postText={item.price?.firstPostText}
                      minOnly
                      decimals={0}
                      separator=","
                    />
                  </span>
                  )}
                  {item.price?.savings && (
                    <span>
                      <Price
                        extraClassNames={{
                          [`${cssPrefix}__buybox-item__price`]: true,
                        }}
                        min={item.price.savings}
                        prefixText={item.price?.savingsPreText}
                        postText={item.price?.savingsPostText}
                        minOnly
                        decimals={0}
                        separator=","
                      />
                    </span>
                  )}
                  <br />
                  {item.price.secondPostText && (<span>{item.price?.secondPostText}</span>)}
                </div>
              )
                : item.fallback && (<div><span>{item.fallback}</span></div>)
              }
              {item.extra && (<span>{item.extra}</span>)}
            </div>
          </div>
          {item.ctaUrl && (
            <Link
              extraClassNames={{
                [`${cssPrefix}__buybox-item__link`]: true,
              }}
              href={item.ctaUrl}
              id={item.ctaId}
              rel="sponsored"
              screenReaderText={item.ctaText}
              onClick={() => handleLinkClick({ ctalabel: item.ctaGALabel, ctaGA4Label: item.ctaGA4Label, ctaGA4Partner: item.ctaGA4Partner })}
            >
              <Icon icon={`${cssPrefix}__ico-caret_up`} />
            </Link>
          )}
        </div>
      </>
    );
  };

  return (
    <div
      className={classNames({
        [`${cssPrefix}__buybox`]: loadStyles,
        ...extraClassNames,
      })}
      id={id}
    >
      <div className={`${cssPrefix}__buybox-top`}>
        <div>
          <div className={`${cssPrefix}__buybox-title`}>{title}</div>
          <div className={`${cssPrefix}__buybox-description`}>{description}</div>
        </div>
        <div className={`${cssPrefix}__buybox-item__logo`}>
          <Image {...partnerLogo?.image} />
        </div>
      </div>
      <div className={`${cssPrefix}__buybox-items`}>
        {items && items.map((item, itemIndex) => (
          <React.Fragment key={`buybox-item_${itemIndex}`}>
            {item && itemHtml(item)}
          </React.Fragment>
        ))}
      </div>

      {subtitle && (
        <div className={`${cssPrefix}__buybox-subtitle`}>{subtitle}</div>
      )}

      {Object.keys(extraItem).length > 0 && itemHtml(extraItem)}

      <div className={`${cssPrefix}__buybox__label`}>{label}</div>
    </div>
  );
};

BuyBox.propTypes = {
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
  description: PropTypes.string,
  extraClassNames: PropTypes.shape(),
  extraItem: PropTypes.shape(),
  id: PropTypes.string,
  items: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  label: PropTypes.string,
  loadStyles: PropTypes.bool,
  partnerLogo: PropTypes.shape().isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

BuyBox.defaultProps = {
  context: {},
  description: null,
  id: '',
  items: {},
  extraClassNames: {},
  extraItem: {},
  loadStyles: true,
  label: 'Advertisement',
  title: null,
  subtitle: null,
};

export default compose(withPolaris)(BuyBox);
