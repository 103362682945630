/* eslint-disable react/prop-types */
import React from 'react';
import { BuyBox } from 'Organisms/BuyBox';
import { ErrorBoundary } from '@autovia-uk/polaris-components/components/organisms/ErrorBoundary';

export const getBuyBox = ({
  props,
}) => {
  const {
    config: {
      partnerships,
      carwow,
    },
    dataLayer,
    layoutData: {
      page: {
        newCarDealsData,
        leasingVehicleData,
        newCarDealsPricing,
      },
    },
  } = props;

  const vehicleMake = dataLayer?.make;
  const vehicleModel = dataLayer?.productFamilyShortName;

  const newCarDealsCtaLink = newCarDealsData?.ctaLink?.url;
  const newCarDealsImage = newCarDealsData?.image;

  const leasingCtaLink = leasingVehicleData?.vehicleCta?.url || carwow?.buybox?.leasing?.ctaUrl;
  const leasingCtaLinkWithUtm = leasingCtaLink && (leasingCtaLink + carwow?.buybox?.leasing?.ctaUrlUtm);

  const newCarPricingLink = newCarDealsPricing?.vehicleItem?.url;
  const newCarPricingLinkWithUtm = newCarPricingLink && (newCarPricingLink + carwow?.buybox?.newCarDeals?.ctaUrlUtm);
  const newCarPricingSaving = newCarDealsPricing?.vehicleFinanceData?.rrpSaving;

  const sellYourCarCtaLinkWithUtm = carwow?.buybox?.sellYourCar?.ctaUrl + carwow?.buybox?.sellYourCar?.ctaUrlUtm;

  /**
   * @type {{extraItem: {ctaUrl: *, ctaId: string, extraClassName: string, ctaText: string, ctaGALabel: string, partnerDetails: {image: {disableTransforms: boolean, size: string, src: string, alt: string, width: number, height: number}}, title: string, subtitle: string, ctaGA4Label: string, ctaGA4Partner: string}, subtitle: string, description: string, title: (string|string), items: [{ctaUrl: string, ctaUrlBC: string, ctaId: string, extraClassName: string, ctaText: string, price: {first: number, firstPostText: string}, ctaGALabel: string, partnerDetails: {image: {disableTransforms: boolean, size: string, src: string, alt: string, width: number, height: number}}, title: string, ctaGA4Label: string, ctaGA4Partner: string, fallback: string}]}}
   */
  const buyBoxData = {
    title: (vehicleMake && vehicleModel) ? `Find your ${vehicleMake} ${vehicleModel}` : 'Find your next car here',
    description: 'Offers from our trusted partners on this car and its predecessors...',
    items: [
      {
        title: 'Leasing deals',
        ctaId: 'buyBox-link-2',
        ctaText: 'Leasing deals link',
        ctaUrl: leasingCtaLinkWithUtm,
        ctaGALabel: 'Main Buy Box | Above Fold | Leasing',
        ctaGA4Label: 'leasingdeals',
        ctaGA4Partner: 'carwow',
        extraClassName: '-leasingdeals',
        price: {
          first: +leasingVehicleData?.vehicleItem?.financePrice,
          firstPostText: ' pm',
        },
        fallback: leasingVehicleData?.vehicleCta?.title,
      },
    ],
    subtitle: 'Or are you looking to sell your car?',
    extraItem: {
      ...carwow?.buybox?.sellYourCar,
      partnerDetails: carwow?.buybox?.buyBoxLogo,
      title: carwow?.buybox?.sellYourCar?.ctaText,
      ctaId: 'buyBox-link-4',
      ctaText: carwow?.buybox?.sellYourCar?.ctaText,
      ctaUrl: sellYourCarCtaLinkWithUtm,
      ctaGALabel: 'Main Buy Box | Above Fold | Sell your car',
      ctaGA4Label: 'sellyourcar',
      ctaGA4Partner: 'carwow',
      fallback: carwow?.buybox?.sellYourCar?.subtitle,
    },
    extraClassNames: {
      noskim: true,
    },
    partnerLogo: {
      image: {
        src: newCarDealsImage?.src || '/public/partners/partner--new-car.svg',
        alt: newCarDealsImage?.alt || 'New car deals logo',
        height: newCarDealsImage?.height || 10,
        width: newCarDealsImage?.width || 10,
        size: newCarDealsImage ? 'manufacturer-logo' : '',
      },
    },
  };

  // Only add newCarDeals section if there is appropriate data.
  if (newCarDealsData?.title && newCarDealsData?.ctaLink) {
    let isCarwowLink = false;

    if (newCarDealsData?.ctaLink?.url.startsWith('http')) { // Basic check for valid url
      const newCarDealsLinkURL = new URL(newCarDealsData?.ctaLink?.url);
      isCarwowLink = newCarDealsLinkURL.hostname.includes('carwow.co.uk');
    }

    buyBoxData.items.unshift({
      title: newCarDealsData?.title || 'New car deals',
      ctaId: 'buyBox-link-1',
      ctaText: newCarDealsData?.ctaLink?.title || 'New car deals',
      ctaUrl: newCarDealsCtaLink,
      ctaUrlBC: newCarDealsCtaLink,
      ctaGALabel: 'Main Buy Box | Above Fold | New Cars',
      ctaGA4Label: 'newcardeals',
      ctaGA4Partner: 'demandGen',
      extraClassName: `-newcardeals${isCarwowLink ? ' -carwow' : ''}`,
      // partnerDetails: {
      //   image: isCarwowLink ? carwow?.buybox?.buyBoxLogo.image : {
      //     src: newCarDealsImage?.src || '/public/partners/partner--new-car.svg',
      //     alt: newCarDealsImage?.alt || 'New car deals logo',
      //     height: newCarDealsImage?.height || 10,
      //     width: newCarDealsImage?.width || 10,
      //     size: newCarDealsImage ? 'manufacturer-logo' : '',
      //   },
      // },
      fallback: newCarDealsData?.ctaLink?.title,
    });
  } else if (newCarPricingLink && newCarPricingSaving) {
    buyBoxData.items.unshift({
      title: carwow?.buybox?.newCarDeals?.title || 'Buy with Carwow',
      ctaId: 'buyBox-link-1',
      ctaText: carwow?.buybox?.newCarDeals?.title || 'Buy with Carwow',
      ctaUrl: newCarPricingLinkWithUtm,
      ctaGALabel: 'Main Buy Box | Above Fold | Carwow New Car Deals',
      ctaGA4Label: 'newcardeals',
      ctaGA4Partner: 'carwow',
      extraClassName: '-carwow',
      price: {
        savings: newCarPricingSaving,
        savingsPreText: carwow?.buybox?.newCarDeals?.subtitlePreText || 'Avg. Carwow saving  ',
        savingsPostText: carwow?.buybox?.newCarDeals?.subtitlePostText || ' off RRP',
      },
      fallback: 'New car deals',
    });
  }

  return (
    <ErrorBoundary>
      <BuyBox {...buyBoxData} id="polaris__buybox" />
    </ErrorBoundary>
  );
};
