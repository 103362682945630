import React from 'react';
import PropTypes from 'prop-types';

import { getRightSideAdConfig } from 'Helpers';

import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { Loading } from '@autovia-uk/polaris-components/components/atoms/Loading';
import { getLoadMore } from 'SharedPartials/getLoadMore'; // LoadMore: Button and Link
import { Search } from '@autovia-uk/polaris-components/components/molecules/Search'; // Button, Input, Form, Label

import { getSearchPageItems } from 'SharedPartialsLocal/getSearchPageItems'; // getArticleGroup

import { getBlankMessage } from './functions';
import { getFilters } from './filters';

// Template styles and imports
import 'Styles/components/templates/_Search.scss';

const SearchTemplate = (props) => {
  const {
    layoutData: {
      page: {
        query: {
          keywords = '',
          filter = 'ALL',
        },
      },
    },
    pagination: {
      setPageRef,
      pages,
      isLoading: isLoadingProp,
      loadMore,
      hasMore,
      nextUrl,
    },
  } = props;

  const filters = getFilters();

  let searchTimeout = null;

  const filterIds = filters.map(({ id }) => id);

  /**
   * Search function
   *
   * @param keywordsSearch
   * @param filterSearch
   */
  const doSearch = (keywordsSearch, filterSearch) => {
    let url = '/search';

    if (keywordsSearch) {
      url += `/${encodeURIComponent(keywordsSearch)}`;
    }
    if (filterSearch && filterSearch !== 'ALL' && filterIds.includes(filterSearch)) {
      if (!keywordsSearch) {
        url += '/'; // Add extra slash for an empty search query.
      }
      url += `/filter/${filterSearch.toLowerCase()}`;
    }
    url += '/';

    window.location.href = url;
  };

  /**
   * Debounce search
   *
   * @param {*} keywords
   * @param {*} filter
   * @param debounce
   */
  const debounceSearch = (keywordsSearch, filterSearch, debounce = true) => {
    if (debounce) {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(doSearch, 1000, keywordsSearch, filterSearch);
    } else {
      doSearch(keywordsSearch, filterSearch);
    }
  };
  const isSearching = !Object.keys(pages).length && isLoadingProp;
  const isLoading = Object.keys(pages).length > 0 && isLoadingProp;
  const showFilters = true;
  const resultPages = Object.entries(pages).length > 0 ? pages : { 0: [] }; // always match initial pages structure from withPagination
  const loadMoreParams = {
    label: isLoading ? 'Loading...' : 'Load More',
    loadMore,
    isLoading,
    extraClassNames: {
      'polaris__load-more--small': true,
    },
    href: nextUrl,
  };

  return (
    <>
      <div className="polaris__body -search-template">
        <main className="polaris__core-content polaris__main" id="main">
          <div className="polaris__content">
            <Heading size={1} extraClassNames={{ '-content-title': true }}>
              Search Results
            </Heading>
          </div>
          <div className="polaris__content--with-bg">
            <div className="polaris__content">
              <Search
                label=""
                placeholder="Search..."
                url="/search"
                searchTerm={keywords}
                filtersLabel=""
                currentFilter={filter || 'ALL'}
                onSubmit={debounceSearch}
                filters={filters}
                showFilters={showFilters}
                inputId="polaris__search--template-input"
                extraClassNames={{ 'polaris__search--results': true }}
              />
            </div>
          </div>
          <div className="polaris__content polaris__simple-grid -search-template">
            <div className="polaris__simple-grid--main">
              {!keywords && (
                <p>
                  Please enter a search term in the search box above.
                </p>
              )}
              <div data-previous="1" ref={setPageRef} />
              {isSearching && (
                <div className="loading-center">
                  <Loading />
                  <p>Loading search results</p>
                </div>
              )}
              {!isSearching && keywords && getSearchPageItems(resultPages, setPageRef, keywords, getBlankMessage(keywords))}
              {!isSearching && hasMore && getLoadMore(loadMoreParams)}
            </div>
            <div className="polaris__simple-grid--aside">
              <Ad {...getRightSideAdConfig()} />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

SearchTemplate.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.objectOf().isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    isLoading: PropTypes.bool,
    loadMore: PropTypes.func,
    page: PropTypes.number,
    pages: PropTypes.objectOf(),
    setPageRef: PropTypes.func,
    hasMore: PropTypes.bool,
    nextUrl: PropTypes.string,
  }).isRequired,
  config: PropTypes.shape({
    globalSettings: PropTypes.shape({
      promoBoxSettings: PropTypes.objectOf(PropTypes.string).isRequired,
    }).isRequired,
  }).isRequired,
};


export default React.memo(SearchTemplate);
