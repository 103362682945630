import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { compose } from 'recompose';

import {
  HtmlSafe,
  withPagination,
} from 'polaris-coreweb/exports';

import injectionEngine from '@autovia-uk/injection-engine';
import rules from '@autovia-uk/injection-default-rules-set';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import {
  getArticleGroupContent,
  getIndex,
  getRelatedContent,
} from 'Helpers';
import { setPageNumber } from 'SharedHelpers/setPageNumber';
import { countBodyInjectedInlineAds } from 'SharedHelpers/countBodyInjectedInlineAds';
import { getPromoBoxSettings } from 'SharedPartialsLocal/getPromoBoxSettings';

import { getAfterContentAd } from 'SharedPartials/getAfterContentAd';
import { getNativeAd } from 'SharedPartials/getNativeAd';
import { getBreadcrumbs } from 'SharedPartialsLocal/getBreadcrumbs';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { getIndexArticlePageHeader } from 'SharedPartials/getIndexArticlePageHeader'; // Heading and TaxonomyFilter
import { getDisqusCollapsible } from 'SharedPartialsLocal/getDisqusCollapsible'; // only has Link
import { getMultipageNavigation } from 'SharedPartialsLocal/getMultipageNavigation';
import { getInThisArticle } from 'SharedPartialsLocal/getInThisArticle'; // NextPages
import { getPostMeta } from 'SharedPartialsLocal/getPostMeta'; // DisqusCount and PostMeta: Date, Image, Link
import { getPrimaryMedia } from 'SharedPartialsLocal/getPrimaryMedia'; // Image, Link, Podcast, Social, SocialEmbed, Video, Gallery
import { ShortAuthorBio } from '@autovia-uk/polaris-components/components/molecules/ShortAuthorBio';
import { getSocialSharingButtons } from 'SharedPartialsLocal/getSocialSharingButtons';
import { getSponsorInfo } from 'SharedPartials/getSponsorInfo';
import { getTags } from 'SharedPartialsLocal/getTags';
import { getArticleGroup } from 'SharedPartialsLocal/getArticleGroup';
import { getBody } from 'SharedPartialsLocal/getBody';
import { Hero } from '@autovia-uk/polaris-components/components/organisms/Hero';
import { getIndexArticlePageContent } from 'SharedPartials/getIndexArticlePageContent'; // Ad, LoadMore and ArticleGroup
import { getArticleBottomComponents } from 'SharedPartialsLocal/getArticleBottomComponents'; //  has getNextPages and getRelatedContent

import { ArticleQueryPagination as query } from './ArticleQuery.graphql';

// Template styles and imports
import 'Styles/components/templates/_Article.scss';

const Article = (props) => {
  const {
    config: {
      breakpoints,
      globalSettings: {
        adSettings,
        adSettings: { afterContentDesktopAds, afterContentMobileAds },
        disqusShortname,
        enableComments,
        promoBoxSettings,
      },
      newsletterConfig,
      siteUrl,
      social: {
        share: { platforms: socialSharingPlatforms },
      },
    },
    dataLayer,
    layoutData: {
      page: {
        associatedContent,
        authors,
        body,
        breadcrumbs,
        filters,
        hideTitle,
        heroImage: {
          heroImage,
          heroImageMobile,
        },
        isSponsored,
        kicker,
        layoutType,
        monetising,
        primaryMedia,
        showFilters,
        sponsor: sponsorInfo,
        subtitle,
        tags,
        title,
        url,
      },
      page,
    },
    isMobile,
  } = props;

  const isAdFreeTemplate = layoutType === 'adFreeTemplate';
  const isCommercialPage = layoutType === 'commercialPage';
  const isCommercialPageTitleImage = layoutType === 'commercialPageTitleImage';

  const taxonomyFilters = Array.isArray(filters) ? filters.map(f => ({
    label: f.title,
    value: f.url,
  })) : null;

  const taxonomyFilterParams = {
    content: taxonomyFilters,
    currentPageUrl: url,
    label: 'Show me:',
    listModeOptions: {
      seeMoreLabel: 'See all',
      seeLessLabel: 'See less',
      icon: 'down',
    },
  };

  const { nodeId } = dataLayer;
  const indexData = getIndex(props);
  const isIndexArticlePage = indexData !== false;
  const multipage = getRelatedContent(associatedContent, 'relatedPages');

  let bodyInjected = injectionEngine({
    body,
    rules,
    props: {
      adSettings,
      breakpoints,
      monetising,
      newsletterConfig,
      pageContentType: 'article',
      promoBoxSettings: getPromoBoxSettings({
        promoBoxSettings,
        ppc: promoBoxSettings.subscriptionArticle,
      }),
    },
  });

  // replace SUBSCRIBE_NEWSLETTER with SHOPWINDOW block
  bodyInjected = bodyInjected && bodyInjected.map((item) => {
    if (item.type === 'SUBSCRIBE_NEWSLETTER') {
      return {
        ...item,
        ...{ type: 'SHOPWINDOW' },
      };
    }
    return { ...item };
  });

  const countInlineAds = countBodyInjectedInlineAds({
    bodyInjected,
    bodyBlockInlineAdDesktop: 'DESKTOP_INLINE_ADVERT',
    bodyBlockInlineAdMobile: 'MOBILE_INLINE_ADVERT',
  });

  const imageToUse = isMobile ? heroImageMobile : heroImage;

  const shortAuthorBio = () => {
    if (!authors || !authors.length) return null;

    const author = authors[0];

    // Return the ShortAuthorBio component
    return (
      <ShortAuthorBio
        authorBio={<HtmlSafe html={author.shortBio || ''} />}
        authorLink={author.url}
        authorImage={author.image}
        authorName={author.name}
        authorRole={author.jobTitle}
        singleImage
      />
    );
  };

  const topComponents = (
    <div className="polaris__simple-grid--main">
      {getMultipageNavigation(multipage, url)}
      {!isCommercialPage && !isCommercialPageTitleImage && getPrimaryMedia(primaryMedia, '-article')}
      {!isCommercialPage && !isCommercialPageTitleImage && getSponsorInfo({ sponsor: sponsorInfo })}
    </div>
  );

  return (
    <>
      <div
        className={classNames({
          polaris__body: true,
          polaris__commercialpage: isCommercialPage,
          polaris__commercialpagetitleimage: isCommercialPageTitleImage,
        })}
      >
        <main className="polaris__main" id="main">
          {(isCommercialPage || isCommercialPageTitleImage) && (
            <Hero
              src={imageToUse?.src}
              alt={imageToUse?.alt}
              height={imageToUse?.height}
              width={imageToUse?.width}
              title={isCommercialPageTitleImage ? title : ''}
              mediaSize="hero-feature"
              {... imageToUse ? '' : { mediaType: null }}
              extraClassNames={{
                'polaris__commercialpage-hero': true,
                'polaris__commercialpagetitleimage-hero': isCommercialPageTitleImage,
                '-no-image': !imageToUse,
              }}
            />
          )}

          <div className="polaris__core-content polaris__content">

            {
              (isCommercialPage || isCommercialPageTitleImage)
                ? (
                  <div>
                    <div className="polaris__commercialpage-sponsor">
                      {getSponsorInfo({
                        sponsor: {
                          ...sponsorInfo,
                          logo: {
                            ...sponsorInfo?.logo,
                            size: 'sponsor-medium',
                          },
                        },
                      })}
                    </div>
                    <div className="polaris__commercialpage-meta">
                      {isSponsored && getBreadcrumbs([], isSponsored, isSponsored ? kicker : '')}
                      {getPostMeta(
                        page,
                        enableComments,
                        siteUrl,
                        dataLayer,
                        disqusShortname,
                      )}
                    </div>
                  </div>

                )
                : getBreadcrumbs(breadcrumbs, isSponsored, kicker)
            }

            {!hideTitle && !isCommercialPageTitleImage && (
              <Heading size={1} extraClassNames={{ '-content-title': true }}>
                {title}
              </Heading>
            )}

            <Heading size={2} extraClassNames={{ '-content-subtitle': true }}>
              {subtitle}
            </Heading>

            {!isCommercialPage && !isCommercialPageTitleImage && getPostMeta(
              page,
              enableComments,
              siteUrl,
              dataLayer,
              disqusShortname,
            )}

          </div>

          <div className={`polaris__content ${isCommercialPage || isCommercialPageTitleImage ? '-full-width' : ''} -body-copy`}>
            {getBody(
              {
                bodyInjected,
                template: 'article',
                layoutType,
                bottomComponents: getArticleBottomComponents({
                  siteUrl,
                  associatedContent,
                  tags,
                  title,
                  url,
                  socialSharingPlatforms,
                }),
                topComponents,
                isMobile,
              },
            )}

            {!isCommercialPage && !isCommercialPageTitleImage && (
              <div className="polaris__core-content polaris__core-content-last polaris__simple-grid--main">
                {getInThisArticle('Continue Reading', url, multipage)}
                <div className="polaris__content-bottom">
                  {getTags(tags, false)}
                  {getSocialSharingButtons({
                    title,
                    siteUrl,
                    url,
                    socialSharingPlatforms,
                    extraClassNames: { 'polaris__article--social': true },
                  })}
                </div>
                {enableComments && getDisqusCollapsible(
                  siteUrl,
                  url,
                  title,
                  nodeId,
                  disqusShortname,
                )}
                {shortAuthorBio()}
              </div>
            )}
          </div>
        </main>
      </div>
      {
        (afterContentDesktopAds || afterContentMobileAds)
        && !isAdFreeTemplate
        && !isCommercialPage
        && !isCommercialPageTitleImage
        && (
          getAfterContentAd({ afterContentDesktopAds, afterContentMobileAds })
        )
      }
      {
        !isCommercialPage
        && !isCommercialPageTitleImage
        && (
          <div className="polaris__core-content polaris__content polaris__core-content-first">
            {getArticleGroup({
              title: 'Recommended',
              content: getArticleGroupContent(associatedContent, 'recommended'),
              extraClassNames: { 'polaris__article--consider': true },
              articleCardProps: {
                datePosition: 'bottom',
                headingSize: 4,
                kickerPosition: 'bottom',
              },
            })}
            {!isIndexArticlePage
                && getArticleGroup({
                  title: 'Most Popular',
                  content: getArticleGroupContent(associatedContent, 'mostPopular', {
                    component: [
                      <React.Fragment key="native_article">
                        {getNativeAd({
                          id: 'native_article',
                          targeting: {
                            position: 'native_article',
                            placement: 'native_article',
                            refresh: 'no',
                          },
                        })}
                      </React.Fragment>,
                    ],
                  }),
                  extraClassNames: { 'polaris__article--most-popular': true },
                  articleCardProps: {
                    datePosition: 'bottom',
                    isHeading: false,
                    kickerPosition: 'bottom',
                  },
                })
              }
            {isIndexArticlePage && (
              <>
                {getIndexArticlePageHeader({
                  indexData,
                  propsData: props,
                  showFilters: showFilters && taxonomyFilters !== null && taxonomyFilters.length > 0,
                  taxonomyFilterParams,
                })}
                {getIndexArticlePageContent(
                  indexData,
                  {
                    ...props,
                    articleCardProps: {
                      datePosition: 'bottom',
                      headingSize: 4,
                      kickerPosition: 'bottom',
                    },
                    articlesPerRow: 4,
                  },
                  countInlineAds,
                  isMobile,
                )}
              </>
            )}
          </div>
        )
      }
    </>
  );
};

Article.propTypes = {
  config: PropTypes.shape({
    ads: PropTypes.shape(),
    breakpoints: PropTypes.shape({
      desktop: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
      mobile: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
    }),
    globalSettings: PropTypes.shape({
      adSettings: PropTypes.shape({
        afterContentDesktopAds: PropTypes.bool,
        afterContentMobileAds: PropTypes.bool,
        afterNavigationDesktopAds: PropTypes.bool,
        afterNavigationMobileAds: PropTypes.bool,
        listGalleryAds: PropTypes.number,
        minNumOfWordsAfterLastInlineAdDesktop: PropTypes.number,
        minNumOfWordsAfterLastInlineAdMobile: PropTypes.number,
        minNumOfWordsBeforeTeads: PropTypes.number,
        numberOfWordsBetweenInlineDesktopAds: PropTypes.number,
        numberOfWordsBetweenInlineMobileAds: PropTypes.number,
        numberOfWordsForFirstInjectionDesktop: PropTypes.number,
        numberOfWordsForFirstInjectionMobile: PropTypes.number,
        refreshBlacklist: PropTypes.string,
        refreshSiteWide: PropTypes.bool,
        refreshTime: PropTypes.number,
      }),
      disqusShortname: PropTypes.string,
      enableComments: PropTypes.bool,
      monetising: PropTypes.arrayOf(
        PropTypes.shape({
          config: PropTypes.arrayOf(
            PropTypes.shape({
              key: PropTypes.string,
              value: PropTypes.string,
            }),
          ),
          enabled: PropTypes.bool,
          service: PropTypes.string,
        }),
      ),
      promoBoxSettings: PropTypes.shape(),
    }).isRequired,
    newsletterConfig: PropTypes.shape(),
    siteUrl: PropTypes.string.isRequired,
    social: PropTypes.shape({
      share: PropTypes.shape({
        platforms: PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.string,
          platform: PropTypes.string,
        })),
      }),
    }),
  }).isRequired,
  dataLayer: PropTypes.shape({
    make: PropTypes.string,
    nodeId: PropTypes.string,
    product: PropTypes.string,
    productFamily: PropTypes.string,
    productFamilyShortName: PropTypes.string,
    productShortName: PropTypes.string,
  }),
  layoutData: PropTypes.shape({
    page: PropTypes.objectOf().isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  pagination: PropTypes.shape({
    hasMore: PropTypes.bool,
    isLoading: PropTypes.bool,
    LoadMore: PropTypes.func,
    loadMore: PropTypes.func,
    nextUrl: PropTypes.string,
    page: PropTypes.number,
    pages: PropTypes.objectOf(),
    setPageRef: PropTypes.func,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

Article.defaultProps = {
  dataLayer: {},
  location: {
    search: '',
  },
};

const MemoArticle = React.memo(Article);

export default compose(
  setPageNumber,
  withPagination({
    query,
    path: ({ associatedContent }) => getRelatedContent(associatedContent, 'indexArticlePageList'),
  }),
  withTemplate,
  withPolaris,
  withHelmet('article'),
)(MemoArticle);
