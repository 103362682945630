/* eslint-disable react/prop-types */
import React from 'react';
import get from 'lodash.get';
import { HtmlSafe } from 'polaris-coreweb/exports';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { getPrimaryMedia } from 'SharedPartialsLocal/getPrimaryMedia';
import { ReportCard } from '@autovia-uk/polaris-components/components/organisms/ReportCard';

import {
  getRelatedContent,
} from 'Helpers';

export const getReportCard = ({
  props,
  extraClassNames = {},
  layout = 'default',
}) => {
  const {
    layoutData: {
      page: {
        associatedContent,
        cons: rawCons,
        pageId,
        priceMax,
        priceMin,
        primaryMedia,
        product,
        pros: rawPros,
        rating,
        reviewType,
        specsPath,
        vehicleSpecList: {
          priceRange: {
            capPriceMax,
            capPriceMin,
          },
        },
      },
    },
  } = props;

  let priceOptions = {};
  let ratings = [];
  const reviewUrl = get(props, 'layoutData.page.ownerReview.url', null);
  const pageUrl = get(props, 'layoutData.page.url', null);

  const ownerReviewUrlsData = ['/audi/a1', '/fiat/500', '/ford/focus', '/mg/zs', '/bmw/2-series'];

  let ownerReviewCtaItem = {};

  const filteredOwnerReviewCtaItem = ownerReviewUrlsData.filter(item => item === pageUrl);

  if (filteredOwnerReviewCtaItem && filteredOwnerReviewCtaItem.length > 0) {
    ownerReviewCtaItem = {
      label: 'Submit your review',
      copy: 'Owner reviews with',
      image: {
        src: '/public/logo-carbuyer.svg',
      },
      ctaUrl: `https://www.carbuyer.co.uk${filteredOwnerReviewCtaItem[0]}/owner-reviews`,
    };
  }

  const relatedContent = getRelatedContent(associatedContent, 'relatedPages');

  const min = capPriceMin || priceMin;
  const max = capPriceMax || priceMax;

  if (min !== 0 || max !== 0) {
    priceOptions = {
      min,
      max,
      minOnly: false,
      prefixText: 'from ',
      priceSeparator: '-',
    };
  }

  let overAllRating = rating;
  let tabRating;
  const ratingsRounding = 1;
  if (relatedContent !== null && relatedContent.length > 0 && relatedContent[0].id !== pageId) {
    overAllRating = relatedContent[0].rating || 0;
    const currentSection = relatedContent.filter(item => item.id === pageId);
    if (currentSection && Array.isArray(currentSection) && currentSection.length) {
      tabRating = {
        showTextRating: true,
        value: rating,
        label: `${currentSection[0].title || 'This Section'} Rating`,
        rounding: ratingsRounding,
      };
    }
  }

  let hasOverAllRating = false;
  if (overAllRating > 0) {
    const shouldHaveHowWeReviewLink = (reviewType && ['car', 'van'].includes(reviewType)) || false;
    hasOverAllRating = true;
    ratings = [
      {
        label: 'Overall Auto Express Rating',
        value: overAllRating,
        showTextRating: true,
        rounding: ratingsRounding,
        extraContent: shouldHaveHowWeReviewLink ? (
          <Link
            href="/reviews/359539/trust-auto-express-reviews"
            extraClassNames={{
              '-how-we-review-link': true,
            }}
          >
            {`How we review ${reviewType}s`}
          </Link>
        ) : null,
      },
    ];
  }

  if (tabRating) {
    ratings.pop(); // clear the ratings array so only the tab rating is shown
    ratings.push(tabRating);
  }

  const toList = (list) => {
    if (list.length > 0) {
      return `<ul>${list.map((item, index) => `<li key={report-list-item-${index}}>${item}</li>`).join('')}</ul>`;
    }

    return null;
  };
  const pros = Array.isArray(rawPros) ? toList(rawPros) : rawPros;
  const cons = Array.isArray(rawCons) ? toList(rawCons) : rawCons;
  const ctas = [];

  // ReportCard wont render if these are empty so neither will we
  if (!pros && !cons && ratings.length === 0 && Object.keys(priceOptions).length === 0) {
    return false;
  }

  if (reviewUrl !== null) {
    ctas.push({
      title: 'OWNER REVIEWS',
      url: reviewUrl,
    });
  }

  // Add SPEC link only if product exists - https://creativesolutions.atlassian.net/browse/APP-412
  // And add SPEC link from specsPath - https://creativesolutions.atlassian.net/browse/PAE-267
  if (product && specsPath) {
    ctas.push({
      title: 'SPECIFICATIONS',
      url: specsPath,
    });
  }

  return (
    <ReportCard
      cons={cons && <HtmlSafe html={cons} />}
      consIcon="polaris__ico-con"
      ctas={ctas}
      extraClassNames={extraClassNames || undefined}
      extraComponent={getPrimaryMedia(primaryMedia, '-report-card')}
      layout={layout}
      ownerReviewCta={ownerReviewCtaItem}
      priceOptions={priceOptions}
      pros={pros && <HtmlSafe html={pros} />}
      prosIcon="polaris__ico-pro"
      ratings={ratings}
      ratingsRounding={ratingsRounding}
      starFill="#B08D03"
      starsDimensions="22px"
      /* eslint-disable-next-line */
      starsPath="M8.902.587l2.162 5.118 5.536.476c.384.033.54.512.249.764l-4.2 3.638 1.259 5.412a.436.436 0 0 1-.65.472L8.5 13.598l-4.757 2.87a.436.436 0 0 1-.65-.473l1.258-5.412-4.2-3.639A.436.436 0 0 1 .4 6.18l5.536-.476L8.098.587a.436.436 0 0 1 .804 0z"
      starsSpacings="5px"
      starsViewBox="0 0 17 17"
    />
  );
};
